/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-06-06 06:50:01 */

const Keys = {
  response: {
    ok: 'response.ok',
    duplicate: 'response.duplicate',
    error: 'response.error'
  },
  submit: 'submit',
  title: 'title',
  text: 'text',
  checkbox: 'checkbox',
  subline: 'subline',
  pleaseSelect: 'pleaseSelect',
  female: 'female',
  male: 'male',
  salutation: 'salutation',
  firstname: 'firstname',
  lastname: 'lastname',
  email: 'email'
}; export default Keys;